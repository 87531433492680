import React, { createContext, useContext, useState } from 'react';

interface IModalContext {
  isOpen: boolean;
  toggle: () => void;
  selectedDimension?: string;
  setSelectedDimension: React.Dispatch<React.SetStateAction<string>>;
}

const ModalContext = createContext<IModalContext | null>(null);

export const ModalProvider = ({ children }) => {
  const [selectedDimension, setSelectedDimension] = useState<string>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const api = { isOpen, toggle, selectedDimension, setSelectedDimension };

  return <ModalContext.Provider value={api}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);

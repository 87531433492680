import React, { useEffect, useState } from 'react';
import slugify from 'slugify';
import { Indicator } from '../../models';
import green from '../../images/status-up.svg';
import red from '../../images/status-down.svg';
import yellow from '../../images/status-neutral.svg';
import grey from '../../images/status-none.svg';
import { Link } from 'gatsby';
import { useQuery } from 'react-query';
import { getData } from '../../api';

interface Props {
  data: Indicator;
}

export const IndicatorCard = ({ data }: Props) => {
  const { data: fetchedEvolutieData, isLoading: isLoadingEvolutieData } =
    useQuery('evolutie', () => getData('Verklaring_pijlen.json'));

  const [evolutieData, setEvolutieData] = useState({});

  const getIndicator = (status: string): string => {
    switch (status) {
      case '-1':
        return red;
      case '0':
        return yellow;
      case '1':
        return green;

      default:
        return grey;
    }
  };

  useEffect(() => {
    //TODO:
    if (fetchedEvolutieData) {
      const foundIndicator = fetchedEvolutieData.find((element) => {
        return element.Indicator_kort === data.titel_kort;
        // return element.Indicator === data.title;
      });
      if (foundIndicator) {
        setEvolutieData(foundIndicator);
      } else {
        setEvolutieData({});
      }
    }
  }, [isLoadingEvolutieData]);

  return (
    <Link
      to={`/themas/${slugify(data.title)}`}
      className="vl-spotlight vl-spotlight--link vl-spotlight--image-icon"
    >
      <article role="none" className="vl-spotlight__main">
        <header role="none" className="vl-spotlight__header">
          <div className="vl-spotlight__image-icon ">
            <div className="vl-badge vl-badge--icon vl-badge--xlarge vl-badge--border vl-spotlight__image-icon-badge">
              <div className="vl-badge__image-icon">
                {
                  <img
                    src={getIndicator(evolutieData['symbool'])}
                    alt="spotlight image"
                  />
                }
              </div>
            </div>
          </div>
          <div className="vl-spotlight__title-wrapper">
            <h3 className="vl-spotlight__title">{data.title}</h3>
          </div>
        </header>
        {/* TODO: check https://www.npmjs.com/package/react-html-parser instead for dangerouslySetInnerHTML */}
        <p
          className="vl-spotlight__text"
          dangerouslySetInnerHTML={{ __html: data.doelstelling }}
        />
      </article>
    </Link>
  );
};

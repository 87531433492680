import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getData } from '../../api';
import { useData } from '../../contexts/useData';

interface Props {
  currentIndicator: any;
}

export const MiniTable = ({ currentIndicator }: Props) => {
  const { percentFormatter } = useData();
  const { data: fetchedEvolutieData, isLoading: isLoadingEvolutieData } =
    useQuery('evolutie', () => getData('Verklaring_pijlen.json'));

  useEffect(() => {
    //TODO:
    if (fetchedEvolutieData) {
      console.log(currentIndicator, 'currentIndicator');
      const foundIndicator = fetchedEvolutieData.find(
        // (element) => element.Indicator === currentIndicator.title,
        (element) => element.Indicator_kort === currentIndicator.titel_kort,
      );
      if (foundIndicator) {
        setEvolutieData(foundIndicator);
      } else {
        setEvolutieData({});
      }
    }
  }, [isLoadingEvolutieData]);

  const [evolutieData, setEvolutieData] = useState({});

  const bodyContent = () => {
    let content: any;
    if (
      evolutieData['vergelijkingsjaar'] &&
      evolutieData['vergelijkingsjaar'] !== '' &&
      evolutieData['vergelijkingsjaar'] !== null
    ) {
      content = (
        <tbody>
          <tr style={{ borderBottom: '1px solid #bbb' }}>
            <td
              className="vl-u-text--small"
              data-title="Jaar"
              style={{ borderRight: '1px solid #bbb', maxWidth: 40 }}
            >
              {evolutieData['laatst gekende jaar']}
            </td>
            <td
              className="vl-u-text--small"
              data-title="value"
              style={{ textAlign: 'right' }}
            >
              {percentFormatter(evolutieData['procent later'] / 100)}
            </td>
            <td
              className="vl-u-text--small"
              data-title="delta"
              style={{ textAlign: 'right', paddingRight: 2, paddingLeft: 0 }}
            >
              {evolutieData['symbool'] === '-1' ? (
                <span style={{ color: '#FF6651' }}>▼ </span>
              ) : evolutieData['symbool'] === '1' ? (
                <span style={{ color: '#00BD99' }}>▲ </span>
              ) : (
                <span style={{ color: '#FFA100' }}>=</span>
              )}
              {parseFloat(evolutieData['verschil in procentpunten']) > 0
                ? '+'
                : ''}
              {percentFormatter(
                parseFloat(evolutieData['verschil in procentpunten']) / 100,
              )}
            </td>
          </tr>
          <tr style={{ borderBottom: '0' }}>
            <td
              className="vl-u-text--small"
              data-title="Jaar"
              style={{ borderRight: '1px solid #bbb', maxWidth: 40 }}
            >
              {evolutieData['vergelijkingsjaar']}
            </td>
            <td
              className="vl-u-text--small"
              data-title="value"
              style={{ textAlign: 'right' }}
            >
              {percentFormatter(evolutieData['procent vroeger'] / 100)}
            </td>
          </tr>
        </tbody>
      );
    } else {
      content = (
        <tbody>
          <tr style={{ borderBottom: '0' }}>
            <td
              className="vl-u-text--small"
              data-title="Jaar"
              style={{ borderRight: '1px solid #bbb' }}
            >
              {evolutieData['laatst gekende jaar']}
            </td>
            <td
              className="vl-u-text--small"
              data-title="value"
              style={{ textAlign: 'right' }}
            >
              {percentFormatter(evolutieData['procent later'] / 100)}
            </td>
            <td></td>
          </tr>
        </tbody>
      );
    }
    return content;
  };

  return (
    <div style={{ marginRight: '20px' }}>
      <table className="vl-data-table vl-data-table-- ">
        <caption
          className="vl-data-table__caption vl-u-text--small"
          style={{ paddingBottom: '6px' }}
        >
          {evolutieData['hoofdindicator']}
        </caption>

        {bodyContent()}
      </table>
    </div>
  );
};

import React from 'react';
import { legendContent, legendRow } from './Legend.module.scss';
import green from '../../images/status-up.svg';
import red from '../../images/status-down.svg';
import yellow from '../../images/status-neutral.svg';
import grey from '../../images/status-none.svg';
import { useData } from '../../contexts';

interface Props {
  className?: string;
}

export const Legend = ({ className }: Props) => {
  const { isDesktop, isBigScreen } = useData();

  const legendItems = [
    {
      title: 'Positieve verandering',
      icon: green,
      description: 'De thema heeft een positieve verandering.',
    },
    {
      title: 'Negatieve verandering',
      icon: red,
      description: 'De thema heeft een negatieve verandering.',
    },
    {
      title: 'Geen verandering',
      icon: yellow,
      description: 'De thema heeft geen verandering.',
    },
    // {
    //   title: 'Enkel data voor 2022',
    //   icon: grey,
    //   description: 'De thema heeft enkel data voor 2022.',
    // },
  ];

  return (
    <section
      className={`vl-infoblock vl-infoblock--question vl-u-spacer-bottom--large ${className}`}
    >
      <header className="vl-infoblock__header" role="presentation">
        {/* <i className="vl-infoblock__header__icon" aria-hidden="true"></i> */}
        <h2
          className={`vl-infoblock__title ${
            (isDesktop || isBigScreen) && 'vl-u-spacer-left--large'
          }`}
        >
          Legende
        </h2>
      </header>
      <div className={`vl-infoblock__content ${legendContent}`}>
        {legendItems.map((item, index) => (
          <div key={index} className={`${legendRow} vl-u-spacer--xsmall`}>
            <img src={item.icon} alt={item.description} />
            <p className="vl-u-spacer-left">{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

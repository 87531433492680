import React from 'react';

interface Props {
  id: string;
  name: string;
  value: number | string;
  label: string;
  checked: boolean;
  onChange: (e: any) => void;
}

export const RadioButton = ({
  id,
  label,
  name,
  value,
  checked,
  onChange,
}: Props) => {
  return (
    <label htmlFor={id}>
      <input
        className="vl-radio__toggle"
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <div className="vl-radio__label">{label}</div>
    </label>
  );
};

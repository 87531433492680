import { Console } from 'console';
import React from 'react';
import { useData, useDrawer, useModal } from '../../contexts';
import { Button } from '../Button/Button';
import { RadioButton } from '../RadioButton/RadioButton';
import { Select } from '../Select/Select';
import {
  flex,
  justifyStart,
  justifyEnd,
  alignCenter,
  sticky,
} from './DataForm.module.scss';

interface Props {
  drawer?: boolean;
}

export const DataForm = ({ drawer = false }: Props) => {
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    selectedYear,
    setSelectedYear,
    selectedRegio,
    setSelectedRegio,
    setHighlightedCategories,
    setSelectedCategories,
    isDesktop,
    isTablet,
    isMobile,
    isBigScreen,
    data,
    highlightedDimension,
    xlsLink,
  } = useData();

  const { toggleDrawer } = useDrawer();
  const { toggle, setSelectedDimension } = useModal();

  const handleChange = (e: any) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name === 'category') {
      const selectedCats = [...categories[value]];
      setSelectedCategory(value);
      setSelectedCategories(selectedCats);
      setHighlightedCategories(selectedCats);
    } else if (name === 'year') {
      setSelectedYear(value);
    } else if (name === 'region') {
      setSelectedRegio(value);
    }
  };

  const handleClickSource = () => {
    setSelectedDimension(highlightedDimension);
    toggle();
  };

  let years: any = [];
  if (data && data.length !== 0) {
    years = [...new Set(data.map((element: any) => element.Jaar))]
      .sort((a: any, b: any) => b - a)
      .slice(0, 10);
    years.unshift('meest recente jaar');
  }

  if (!drawer) {
    if (isBigScreen || isDesktop) {
      return (
        <form
          className={`vl-form-grid vl-form-grid--v-bottom ${sticky}`}
          onSubmit={(e) => e.preventDefault()}
          style={{
            paddingTop: '0.5rem',
            margin: isBigScreen ? '0' : '0 -3rem',
            paddingBottom: '1rem',
            paddingRight: '48px',
            backgroundColor: 'rgb(249, 250, 251)',
            borderBottom: '1px solid #cbd2da',
            borderTop: '1px solid #cbd2da',
          }}
        >
          <div
            className="vl-form-col--2-12"
            style={{
              paddingLeft: '5rem',
            }}
          >
            {isDesktop && (
              <Button
                label="Filters"
                variant="secondary"
                onClick={toggleDrawer}
              />
            )}
          </div>

          <div className="vl-form-col--2-12">
            <Select
              id="select_year"
              label="Kies een jaar"
              name="year"
              value={selectedYear}
              onChange={handleChange}
              data={years}
            />
          </div>
          <div className="vl-form-col--2-12">
            <Select
              id="select_category"
              label="Kies een categorisatie"
              name="category"
              value={selectedCategory}
              onChange={handleChange}
              data={categories && Object.keys(categories)}
              disabled={categories && Object.keys(categories).length <= 1}
            />
          </div>
          <div className="vl-form-col--1-12"></div>
          <div className="vl-form-col--2-12">
            <label
              className="vl-form__label __field__label"
              style={{ marginBottom: '12px' }}
            >
              Kies een gewest
            </label>
            <div className={`vl-u-display-flex ${justifyStart}`}>
              <RadioButton
                label="Vlaams"
                id="group-1-radio-1"
                name="region"
                value="Vlaanderen"
                checked={selectedRegio === 'Vlaanderen'}
                onChange={handleChange}
              />
              <RadioButton
                label="Brussels"
                id="group-1-radio-2"
                name="region"
                value="Brussel"
                checked={selectedRegio === 'Brussel'}
                onChange={handleChange}
              />
            </div>
          </div>
          <div
            className={`vl-form-col--3-12 ${flex} ${alignCenter} ${justifyEnd}`}
          >
            <Button
              label="Bron"
              className="vl-u-spacer-right"
              variant="secondary"
              onClick={handleClickSource}
            />
            <a href={xlsLink}>
              <button
                type="button"
                className="vl-button vl-button--icon-before"
                vl-button
              >
                <i
                  className="vl-button__icon vl-button__icon--before vl-vi vl-vi-arrow-bottom"
                  aria-hidden="true"
                ></i>
                <span className="vl-button__label">XLS</span>
              </button>
            </a>
          </div>
        </form>
      );
    }
    if (isMobile || isTablet) {
      return (
        <form
          className={`vl-form-grid vl-form-grid--v-bottom ${sticky}`}
          style={{
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            margin: '0 -3rem',
            backgroundColor: 'rgb(249, 250, 251)',
            borderBottom: '1px solid #cbd2da',
            borderTop: '1px solid #cbd2da',
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          <div
            className="vl-form-col--6-12"
            style={{
              marginTop: '6px',
              marginBottom: '6px',
              paddingLeft: '3rem',
            }}
          >
            <Button
              label="Filters"
              variant="secondary"
              onClick={toggleDrawer}
            />
          </div>
          <div
            className="vl-form-col--6-12"
            style={{
              marginTop: '6px',
              marginBottom: '6px',
              display: 'flex',
              justifyContent: 'right',
              paddingRight: '3rem',
            }}
          >
            <a href={xlsLink} download>
              <button
                className="vl-button vl-button--icon-before"
                vl-button
                type="button"
              >
                <i
                  className="vl-button__icon vl-button__icon--before vl-vi vl-vi-arrow-bottom"
                  aria-hidden="true"
                ></i>
                <span className="vl-button__label">XLS</span>
              </button>
            </a>
          </div>
        </form>
      );
    }
    return <div></div>;
  } else {
    return (
      <form
        className={`vl-form-grid vl-form-grid--v-bottom ${sticky}`}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="vl-form-col--1-1 vl-u-spacer-bottom ">
          <Select
            id="select_year"
            label="Kies een jaar"
            name="year"
            value={selectedYear}
            onChange={handleChange}
            data={years}
          />
        </div>
        <div className="vl-form-col--1-1 vl-u-spacer-bottom">
          <Select
            id="select_category"
            label="Kies een categorisatie"
            name="category"
            value={selectedCategory}
            onChange={handleChange}
            data={categories && Object.keys(categories)}
            disabled={categories && Object.keys(categories).length <= 1}
          />
        </div>

        <div className="vl-form-col--1-1 vl-u-spacer-bottom">
          <label
            className="vl-form__label __field__label"
            style={{ marginBottom: '12px' }}
          >
            Kies een gewest
          </label>
          <div className={`vl-u-display-flex ${justifyStart}`}>
            <RadioButton
              label="Vlaams"
              id="group-1-radio-1"
              name="region"
              value="Vlaanderen"
              checked={selectedRegio === 'Vlaanderen'}
              onChange={handleChange}
            />
            <RadioButton
              label="Brussels"
              id="group-1-radio-2"
              name="region"
              value="Brussel"
              checked={selectedRegio === 'Brussel'}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    );
  }
};

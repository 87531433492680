import React from 'react';
import { useModal } from '../../contexts';
import { Button } from '../Button/Button';
import {
  modal,
  modalContent,
  modalHeader,
  modalFooter,
  modalTitle,
  modalBody,
} from './Modal.module.scss';

interface Props {
  title: string;
  bronHtml: string;
  definitieHtml: string;
  bodyHtml: string;
}

export const Modal = ({ title, bronHtml, definitieHtml, bodyHtml }: Props) => {
  const { toggle } = useModal();
  return (
    <div className={modal}>
      <div className={modalContent}>
        <div className={modalHeader}>
          <h4 className={`vl-title vl-title--h4 ${modalTitle}`}>{title}</h4>
          <div onClick={toggle}>
            <i
              className="vl-modal-dialog__close__icon vl-vi vl-vi-cross"
              aria-hidden="true"
            ></i>
            <span className="vl-u-visually-hidden">Dialoogvenster sluiten</span>
          </div>
        </div>
        <div className={modalBody}>
          <p dangerouslySetInnerHTML={{ __html: bronHtml }} />
          <br />
          <p dangerouslySetInnerHTML={{ __html: definitieHtml }} />
          <br />
          <p dangerouslySetInnerHTML={{ __html: bodyHtml }} />
        </div>
        <div className={modalFooter}>
          <Button label="Sluiten" onClick={toggle} />
        </div>
      </div>
    </div>
  );
};

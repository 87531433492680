import React from 'react';
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useData } from '../../contexts';

interface Props {
  element: any;
  dim: any;
  fill: any;
  dataKey: any;
  barSize: any;
  opacity: any;
}

export const SimpleBarChart = ({
  element,
  dim,
  fill,
  dataKey,
  barSize,
  opacity,
}: Props) => {
  const { percentFormatter, categoryField } = useData();

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;

    const valueOffset =
      element[dim].bar.unit === '%' ? 0.6 : element[dim].line.max * 0.6;
    const fireOffset = value < valueOffset;
    const offset = fireOffset ? -44 : 5;

    return (
      <text
        x={x + width - offset}
        y={y + height / 2 + 5}
        fill={fireOffset ? '#444' : '#fff'}
        textAnchor="end"
      >
        {element[dim].bar.unit === '%'
          ? percentFormatter(value)
          : parseInt(value)}
      </text>
    );
  };

  const getDomain = () => {
    return element[dim].line.unit === '%' ? [0, 1] : [0, element[dim].line.max];
  };

  if (element[dim]) {
    if (element[dim].bar.length === 0) {
      return (
        <p
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            paddingLeft: '8px',
          }}
        >
          Geen data
        </p>
      );
    } else {
      return (
        <ResponsiveContainer
          width="100%"
          height="100%"
          id={`tr_${element[categoryField]}`}
        >
          {element[dim] ? (
            <BarChart data={element[dim].bar} layout="vertical" key="2">
              <Bar
                // TODO: Enable animation, but at this moment, the labelist disappears when the hoverColor changes
                // SOlution : https://github.com/recharts/recharts/issues/829#issuecomment-647998463
                animationDuration={500}
                isAnimationActive={false}
                dataKey={dataKey}
                fill={fill}
                opacity={opacity}
                barSize={barSize}
                background={{ fill: '#eee' }}
              >
                <LabelList dataKey={dataKey} content={renderCustomizedLabel} />
              </Bar>
              <XAxis type="number" domain={getDomain()} hide={true} />
              <YAxis type="category" dataKey={categoryField} hide={true} />
            </BarChart>
          ) : null}
        </ResponsiveContainer>
      );
    }
  } else return <div></div>;
};

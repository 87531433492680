import { Link } from 'gatsby';
import React from 'react';
import { Navigation } from '../Navigation/Navigation';
import { coloredHeader, imageWidth } from './Header.module.scss';

interface Tab {
  link: string;
  name: string;
}

const Header = () => {
  const tabs: Tab[] = [
    {
      link: '/',
      name: 'Dashboard',
    },
    {
      link: '/survey',
      name: 'Survey',
    },
    {
      link: '/links',
      name: 'Links',
    },
  ];

  return (
    <header
      className={`vl-content-header vl-content-header--small vl-content-header--has-logo vl-content-header--no-image vl-content-header--show-mobile ${coloredHeader}`}
    >
      <div className="vl-region vl-region--no-space-bottom">
        <div className="vl-layout">
          <div className="vl-content-header__content">
            <div className="vl-content-header__logo">
              <div className={imageWidth}>
                <Link to="/">
                  <img
                    src="https://assets.vlaanderen.be/image/upload/c_fit,dpr_2.0,q_auto:eco,w_200/abb_logo_vlaanderen_is_samenleven_vol"
                    alt="Samenleven"
                    width="200"
                    height="78"
                  />
                </Link>
              </div>
            </div>
          </div>
          <Navigation tabs={tabs} />
        </div>
      </div>
    </header>
  );
};

export default Header;

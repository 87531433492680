import React from 'react';

interface Props {
  data: number[] | string[];
  label: string;
  name: string;
  id: string;
  value?: any;
  onChange?: (e: any) => void;
  withEmptyOption?: boolean;
  disabled?: boolean;
}

export const Select = ({
  data,
  label,
  name,
  id,
  onChange,
  value,
  withEmptyOption = false,
  disabled = false,
}: Props) => {
  return (
    <>
      <label htmlFor={id} className="vl-form__label __field__label">
        {label}
      </label>
      <select
        name={name}
        id={id}
        onChange={onChange}
        value={value}
        className="vl-select--block vl-select vl-select--block"
        tabIndex={0}
        data-vl-select
        disabled={disabled ? true : false}
      >
        {withEmptyOption && <option value="none">Geen</option>}

        {data &&
          data.length > 0 &&
          data.map((element, index) => {
            return (
              <option key={`select_${name}_${index}`} value={element}>
                {element}
              </option>
            );
          })}
      </select>
    </>
  );
};

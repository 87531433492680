import React from 'react';
import * as d3 from 'd3';
import { createContext, useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
interface IDataContext {
  dimensions: string[];
  setDimensions: any;
  selectedIndicator: string;
  setSelectedIndicator: any;
  selectedIndicatorLong: any;
  setSelectedIndicatorLong: any;
  selectedRegio: string;
  setSelectedRegio: any;
  selectedCategory: string;
  setSelectedCategory: any;
  selectedYear: string;
  setSelectedYear: any;
  highlightedCategories: string[];
  setHighlightedCategories: any;
  highlightedDimension: string;
  setHighlightedDimension: any;
  colors: string[];
  categories: any;
  setCategories: any;
  percentFormatter: any;
  percentNoCommaFormatter: any;
  selectedCategories: any;
  setSelectedCategories: any;
  BigScreen: any;
  Desktop: any;
  Tablet: any;
  Mobile: any;
  Default: any;
  hoveredCategory: any;
  setHoveredCategory: any;
  hoverColor: any;
  allDimensions: any;
  setAllDimensions: any;
  categoryField: any;
  setCategoryField: any;
  isBigScreen: any;
  isDesktop: any;
  isTablet: any;
  isMobile: any;
  isNotMobile: any;
  getColorForCategory: any;
  data: any;
  setData: any;
  dropdownCategories: any;
  dropdownCategoriesDefault: any;
  payloadIndex: any;
  setPayloadIndex: any;
  xlsLink: string;
  setXlsLink: any;
}

const DataContext = createContext<IDataContext | null>(null);

export const BigScreen = ({ children }) => {
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  return isBigScreen ? children : null;
};

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1824 });
  return isDesktop ? children : null;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 });
  return isTablet ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

export const getColorForCategory = (colors, highlightedCategories, d, i) => {
  // let color = colors[0];
  // if (highlightedCategories.includes(String(d))) {
  //   color = colors[i + 1];
  // } else {
  //   color = colors[0];
  // }
  // return color;
  return colors[i + 1];
};

export const DataProvider = ({ children }) => {
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1824 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  // const colors = [
  //   '#AAA',
  //   '#24789C',
  //   '#f57794',
  //   '#4E999E',
  //   '#A87E6A',
  //   '#FE9920',
  // ];

  // const colors = [
  //   '#AAA',
  //   '#2E4052',
  //   '#FFC857',
  //   '#E5323B',
  //   '#A997DF',
  //   '#929084',
  //   '#BDD9BF',
  // ];

  // const colors = [
  //   '#AAA',
  //   '#058ED9',
  //   '#FF934F',

  //   '#A997DF',
  //   '#E1DAAE',
  //   '#2D3142',
  //   '#CC2D35',
  // ];

  const colors = [
    // '#AAA',
    // '#50514F',
    // '#FFE066',
    // '#F25F5C',
    // '#247BA0',
    // '#70C1B3',
    '#AAA',
    '#005656',
    '#009fe3',
    '#e6007e',
    '#baac00',
    '#ea580c',
  ];
  const hoverColor = '#A997DF';
  const dropdownCategories = {
    Herkomst: {
      totaal: ['totaal'],
      herkomst: ['BE', 'niet-BE'],
      'herkomst - regio': ['BE', 'EU', 'niet-EU'],
      'herkomst - regio - generatie': [
        'BE',
        'EU generatie 1',
        'EU generatie 2',
        'niet-EU generatie 1',
        'niet-EU generatie 2',
      ],
    },
    Thuistaal: {
      totaal: ['totaal'],
      thuistaal: ['Nederlands', 'andere taal'],
    },
    Nationaliteit_moeder: {
      totaal: ['totaal'],
      'geboortenationaliteit moeder': ['BE', 'niet-BE'],
      'geboortenationaliteit moeder - regio': ['BE', 'EU', 'niet-EU'],
    },
    Oudste_nationaliteit: {
      totaal: ['totaal'],
      nationaliteit: ['EU', 'niet-EU', 'onbekend'],
    },
  };

  const dropdownCategoriesDefault = {
    Herkomst: 'herkomst - regio - generatie',
    Thuistaal: 'thuistaal',
    Nationaliteit_moeder: 'geboortenationaliteit moeder - regio',
    Oudste_nationaliteit: 'nationaliteit',
  };

  const percentFormatter = d3.format('.1%');
  const percentNoCommaFormatter = d3.format('.0%');
  const [selectedIndicator, setSelectedIndicator] = useState('');
  const [selectedIndicatorLong, setSelectedIndicatorLong] = useState('');
  const [allDimensions, setAllDimensions] = useState({
    Herkomst: [
      'Werkzaamheid',
      'Werkloosheid',
      'Ondernemerschap',
      'Werken op niveau',
    ],
  });
  const [dimensions, setDimensions] = useState([
    'Werkzaamheid',
    'Werkloosheid',
    'Ondernemerschap',
    'Werken op niveau',
  ]);
  const [highlightedDimension, setHighlightedDimension] =
    useState('Werkzaamheid');
  const [categoryField, setCategoryField] = useState('Herkomst'); // "Soort": "Herkomst" en "Soort_waarde": "BE" //
  const [categories, setCategories] = useState(
    dropdownCategories[categoryField],
  );
  const [selectedCategory, setSelectedCategory] = useState('totaal');
  const [selectedRegio, setSelectedRegio] = useState('Vlaanderen');
  const [selectedYear, setSelectedYear] = useState('meest recente jaar');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [highlightedCategories, setHighlightedCategories] = useState([]);
  const [hoveredCategory, setHoveredCategory] = useState('');
  const [data, setData] = useState([]);
  const [payloadIndex, setPayloadIndex] = useState(0);
  const [xlsLink, setXlsLink] = useState<string>(null);

  const api = {
    dimensions,
    setDimensions,
    selectedIndicator,
    setSelectedIndicator,
    selectedRegio,
    setSelectedRegio,
    selectedCategory,
    setSelectedCategory,
    selectedYear,
    setSelectedYear,
    highlightedCategories,
    setHighlightedCategories,
    highlightedDimension,
    setHighlightedDimension,
    colors,
    categories,
    percentFormatter,
    percentNoCommaFormatter,
    selectedCategories,
    setSelectedCategories,
    BigScreen,
    Desktop,
    Tablet,
    Mobile,
    Default,
    hoveredCategory,
    setHoveredCategory,
    hoverColor,
    allDimensions,
    setAllDimensions,
    categoryField,
    setCategoryField,
    setCategories,
    isBigScreen,
    isDesktop,
    isTablet,
    isMobile,
    isNotMobile,
    getColorForCategory,
    data,
    setData,
    dropdownCategories,
    dropdownCategoriesDefault,
    payloadIndex,
    setPayloadIndex,
    selectedIndicatorLong,
    setSelectedIndicatorLong,
    xlsLink,
    setXlsLink,
  };
  return <DataContext.Provider value={api}>{children}</DataContext.Provider>;
};

export const useData = () => useContext(DataContext);

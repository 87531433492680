import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useData } from '../../contexts';
import { customTooltip } from './SimpleLineChart.module.scss';

interface Props {
  element: any;
  dim: any;
  dataKey: any;
  stroke: any;
  opacity: any;
}

export const SimpleLineChart = ({
  element,
  dim,
  dataKey,
  stroke,
  opacity,
}: Props) => {
  const {
    highlightedDimension,
    categoryField,
    isDesktop,
    isBigScreen,
    highlightedCategories,
  } = useData();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      if (payload[0].payload.Dimensie === highlightedDimension) {
        return (
          <div
            className={`vl-u-text--xsmall ${customTooltip}`}
            style={{
              paddingTop: 0,
            }}
          >
            {/* {`${percentFormatter(payload[0].value)}`} */}
          </div>
        );
      } else return <div></div>;
    }

    return null;
  };

  const tooltipChart = () => {
    if (highlightedCategories.includes(element[categoryField])) {
      if (isDesktop || isBigScreen) {
        if (highlightedDimension === dim) {
          return <Tooltip content={<CustomTooltip />} active={false} />;
        } else {
          return '';
        }
      } else {
        return <Tooltip content={<CustomTooltip />} active={false} />;
      }
    }
  };

  const getDomain = () => {
    return element[dim].line.unit === '%' ? [0, 1] : [0, element[dim].line.max];
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      id={`tr_${element[categoryField]}`}
    >
      <LineChart syncId={`lineChartId_${dim}`} data={element[dim].line}>
        <Line
          animationDuration={500}
          type="linear"
          dataKey={dataKey}
          stroke={stroke}
          opacity={opacity}
          strokeWidth={2}
          dot={false}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis type="category" dataKey={categoryField} hide={true} />
        <YAxis type="number" domain={getDomain()} hide={true} />
        {tooltipChart()}
      </LineChart>
    </ResponsiveContainer>
  );
};

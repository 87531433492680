import React from 'react';
import { useData, useDrawer } from '../../contexts';
import { DataForm } from '../DataForm/DataForm';
import { IndicatorForm } from '../IndicatorForm/IndicatorForm';
import { MiniTable } from '../MiniTable/MiniTable';
import { drawer, shadow, closed } from './Drawer.module.scss';

interface Props {
  isOpen: boolean;
  currentIndicator: any;
  data: any[];
}

export const Drawer = ({ isOpen, currentIndicator, data }: Props) => {
  const { isTablet, isMobile } = useData();
  const { toggleDrawer } = useDrawer();
  return (
    <div
      className={`${drawer} ${isOpen ? shadow : closed}`}
      style={{ overflowY: 'auto', overflowX: 'hidden' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <div onClick={toggleDrawer}>
          <i
            className="vl-modal-dialog__close__icon vl-vi vl-vi-cross"
            aria-hidden="true"
          ></i>
          <span className="vl-u-visually-hidden">Dialoogvenster sluiten</span>
        </div>
      </div>
      <IndicatorForm currentIndicator={currentIndicator} />

      {(isMobile || isTablet) && <DataForm drawer={true}></DataForm>}
      {(isMobile || isTablet) && (
        <div style={{ marginTop: 20, borderTop: '1px solid #ccc' }}>
          <MiniTable currentIndicator={currentIndicator} />
        </div>
      )}
    </div>
  );
};

import './src/sass/_govflanders.scss';
import '@govflanders/vl-ui-core';
import '@govflanders/vl-ui-util';
import '@govflanders/vl-ui-tabs';
import '@govflanders/vl-ui-modal';
import React from 'react';
import {
  NavigationProvider,
  DataProvider,
  DrawerProvider,
  ModalProvider,
} from './src/contexts';
import { Layout } from '././src/components';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <NavigationProvider>
        <DataProvider>
          <DrawerProvider>
            <ModalProvider>
              <Layout>{element}</Layout>
            </ModalProvider>
          </DrawerProvider>
        </DataProvider>
      </NavigationProvider>
    </QueryClientProvider>
  );
};

/* Voor volgende componenten moet er ook een JS file worden geinclude. Enkel wanneer we deze zouden gebruiken
 // Forms
 - Datepicker: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-datepicker/
 - Doubleinput: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-double-input/
 - DynamicLabel: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-dynamic-label/
 - Formvalidation: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-form-validation/
 - Multiselect: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-multiselect/
 - Pattern: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-pattern/
 - Pillinput: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-pill-input/
 - Range: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-range/
 - Scheduler: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-scheduler/
 - Select: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-select/
 - Showonchecked: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-show-on-checked/
 - Showonselect: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-show-on-select/
 - Upload: https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-upload/
 // Components
 - ContactCard: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-contact-card/
 - Datatable: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-data-table/
 - Functionalheader: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-functional-header/
 - Gallery: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-gallery/
 - Infotext: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-infotext/
 - Map: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-map/
 - Progressbar: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-progress-bar/
 - Sidenavigation: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-side-navigation/
 - Tabs: https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-tabs/
 // JS Components
 - All JS components: https://overheid.vlaanderen.be/webuniversum/v3/documentation/js-components
*/

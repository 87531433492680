import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { IndicatorCard } from '..';
import { mapToIndicatorModel } from '../../mappers/indicator.mapper';
import { Indicator } from '../../models';

export const IndicatorsGrid = () => {
  const data = useStaticQuery(graphql`
    query findIndicators {
      allNodeIndicator {
        nodes {
          id
          title
          field_titel_kort
          field_doelstelling
          field_volgorde
        }
      }
    }
  `);

  const indicators: Indicator[] =
    data.allNodeIndicator.nodes.map(mapToIndicatorModel);

  return (
    <>
      <div className="vl-grid vl-grid--is-stacked">
        {indicators
          .sort((a, b) => a.volgorde - b.volgorde)
          .map((indicator) => (
            <div
              key={indicator.id}
              className="vl-col--3-12 vl-col--4-12--m vl-col--6-12--s vl-col--12-12--xs"
            >
              <IndicatorCard key={indicator.id} data={indicator} />
            </div>
          ))}
      </div>
    </>
  );
};

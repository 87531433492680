import React from 'react';

interface Props {
  variant?: 'primary' | 'secondary';
  label: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export const Button = ({
  variant = 'primary',
  label,
  onClick,
  className,
  disabled,
}: Props) => {
  return (
    <button
      className={`vl-button ${
        variant === 'secondary' && 'vl-button--secondary'
      } ${className} ${disabled && 'vl-button--disabled'}`}
      onClick={onClick}
      vl-button
      disabled={disabled}
    >
      <span className="vl-button__label">{label}</span>
    </button>
  );
};

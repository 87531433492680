const getDataEndpoint = () => {
  return window && window.location && window.location.origin && window.location.origin.includes('barometersamenleven.be')
    // PROD
    ? 'https://barsamstorageprod.blob.core.windows.net/barsam-json'
    // DEV
    : 'https://barsamstorage.blob.core.windows.net/barsam-json';
}

export const getData = async (query: string) => {
  const res = await fetch(`${getDataEndpoint()}/${query}`);
  const data = await res.json();
  return data;
};

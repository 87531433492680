import React from 'react';
import FlemishFooter from '../FlemishFooter/FlemishFooter';
import FlemishHeader from '../FlemishHeader/FlemishHeader';
import Header from '../Header/Header';

export const Layout = ({ children }) => {
  return (
    <>
      <FlemishHeader />
      <Header />
      <main>{children}</main>
      <FlemishFooter />
    </>
  );
};

import React, { createContext, useContext, useState } from 'react';

interface IDrawerContext {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
}

const DrawerContext = createContext<IDrawerContext | null>(null);

export const DrawerProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const api = { isDrawerOpen, toggleDrawer };
  return (
    <DrawerContext.Provider value={api}>{children}</DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);

import { Link } from 'gatsby';
import React from 'react';
import { useNavigation } from '../../contexts';
import { isBrowser } from '../../utils/utils';
import {
  coloredLink,
  coloredLinkActive,
  noBorder,
} from './Navigation.module.scss';

interface Tab {
  link: string;
  name: string;
}

interface Props {
  tabs: Tab[];
}

export const Navigation = ({ tabs }: Props) => {
  const { currentTab, setCurrentTab } = useNavigation();

  React.useEffect(() => {
    if (isBrowser() && window.vl) {
      window.vl.tabs.dressAll();
    }
  });

  return (
    <div data-vl-tabs data-vl-tabs-responsive-label="Navigatie">
      <div className="vl-tabs__wrapper">
        <div
          className={`vl-tabs vl-tabs--alt vl-u-spacer--none ${noBorder}`}
          data-vl-tabs-list
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <Link
              key={index}
              className={`vl-tab ${coloredLink}  ${
                tab.link === currentTab && `vl-tab--active ${coloredLinkActive}`
              }`}
              id={`vl-tab2-nummer-${index}`}
              role="tab"
              data-vl-tab
              aria-controls={`vl-tab2-nummer-${index}-pane`}
              onClick={() => setCurrentTab(tab.link)}
              to={tab.link}
            >
              {tab.name}
            </Link>
          ))}
        </div>
        <button
          type="button"
          data-vl-tabs-toggle
          aria-expanded="false"
          className="vl-tabs__toggle"
          data-vl-close="false"
        >
          <span>Navigatie</span>
        </button>
      </div>
    </div>
  );
};

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-templates-indicator-details-indicator-details-tsx": () => import("./../../../src/templates/IndicatorDetails/IndicatorDetails.tsx" /* webpackChunkName: "component---src-templates-indicator-details-indicator-details-tsx" */)
}


import React, { useState, useEffect } from 'react';
import { useData } from '../../contexts';
import { paddingLeft2, padding2, dot } from './DataGrid.module.scss';
import { SimpleLineChart } from '../SimpleLineChart/SimpleLineChart';
import { SimpleBarChart } from '../SimpleBarChart/SimpleBarChart';

interface Props {}

export const DataGrid = ({}: Props) => {
  const {
    dimensions,
    categories,
    selectedCategory,
    colors,
    highlightedCategories,
    selectedYear,
    percentFormatter,
    setHighlightedCategories,
    selectedRegio,
    highlightedDimension,
    setHighlightedDimension,
    hoveredCategory,
    setHoveredCategory,
    selectedIndicatorLong,
    allDimensions,
    categoryField,
    selectedIndicator,
    getColorForCategory,
    data,
    setPayloadIndex,
    isBigScreen,
  } = useData();

  const [gridData, setGridData] = useState([]);
  const [radioButtonsState, setRadioButtonsState] = useState({});
  const headerData = ['id', 'Soort_waarde', ...dimensions];

  const heightRow = isBigScreen
    ? 44
    : [...categories[selectedCategory]] &&
      [...categories[selectedCategory]].length !== 0 &&
      [...categories[selectedCategory]].length > 3
    ? 32
    : 48;
  const paddingLabel = (heightRow - 21) / 2;
  const barHeight = 16;

  const prepGridData = async () => {
    if (data && data.length !== 0) {
      let color = colors[0];
      const filteredData = data.filter((d) => {
        if (allDimensions[categoryField]) {
          return (
            allDimensions[categoryField].includes(d.Dimensie) &&
            categories[selectedCategory].includes(d['Soort_waarde'])
          );
        } else {
          return [];
        }
      });

      const uniqueCat = [
        ...new Set(filteredData.map((d) => d['Soort_waarde'])),
      ];

      if (uniqueCat.includes('Nederlands')) {
        uniqueCat.sort(function (a, b) {
          const keyA = a;
          const keyB = b;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      }
      const newGridData = uniqueCat.map((d, i) => {
        color = getColorForCategory(colors, highlightedCategories, d, i);

        const tmp = {
          id: i,
          [categoryField]: d,
        };

        allDimensions[categoryField].forEach((dim) => {
          let maxYearDim: any = selectedYear; // this variable will hold the latest year for which there is data on dimension level
          let maxYearDimCat: any = selectedYear; // this variable will hold the latest year for which there is data on dimension / category level

          let minYearDim: any = selectedYear;

          if (selectedYear === 'meest recente jaar') {
            const tmpDataCat = data.filter((el) => {
              return el.Dimensie === dim && el['Soort_waarde'] === d;
            });
            const uniqueYearsCat = [
              ...new Set(tmpDataCat.map((d) => d['Jaar'])),
            ];
            maxYearDimCat =
              uniqueYearsCat &&
              uniqueYearsCat.length !== 0 &&
              uniqueYearsCat.reduce((prev, current) =>
                prev > current ? prev : current,
              );

            const tmpData = data.filter((el) => {
              return el.Dimensie === dim;
            });

            const uniqueYears = [...new Set(tmpData.map((d) => d['Jaar']))];
            maxYearDim =
              uniqueYears &&
              uniqueYears.length !== 0 &&
              uniqueYears.reduce((prev, current) =>
                prev > current ? prev : current,
              );
          }

          const tmpData = data.filter((el) => {
            return (
              el.Dimensie === dim &&
              parseInt(el.Jaar) > parseInt(maxYearDim) - 10
            );
          });

          const uniqueYears = [...new Set(tmpData.map((d) => d['Jaar']))];

          minYearDim =
            uniqueYears &&
            uniqueYears.length !== 0 &&
            uniqueYears.reduce((prev, current) =>
              prev < current ? prev : current,
            );

          const barData = data.filter((el) => {
            return (
              el.Jaar === maxYearDimCat &&
              el.Dimensie === dim &&
              el['Soort_waarde'] === d
            );
          });
          barData.color = color;
          barData.valueY = parseFloat(barData.Verhouding);
          barData.unit = dim === 'Deelname NT2-cursus' ? '' : '%';

          const maxLineValueData = data.filter((el) => {
            return (
              parseInt(el.Jaar) > parseInt(maxYearDim) - 10 &&
              parseInt(el.Jaar) <= parseInt(maxYearDim) &&
              el.Dimensie === dim &&
              categories[selectedCategory].includes(el['Soort_waarde'])
            );
          });

          const uniqueValues = [
            ...new Set(maxLineValueData.map((d) => d['Verhouding'])),
          ];

          const maxLineValue: any =
            uniqueValues &&
            uniqueValues.length !== 0 &&
            uniqueValues.reduce((prev, current) =>
              prev > current ? prev : current,
            );

          const lineData = data.filter((el) => {
            return (
              parseInt(el.Jaar) > parseInt(maxYearDim) - 10 &&
              parseInt(el.Jaar) <= parseInt(maxYearDim) &&
              el.Dimensie === dim &&
              el['Soort_waarde'] === d
            );
          });

          if (lineData && lineData.length !== 0) {
            const yearsFiltered = [
              ...new Set(
                lineData.map((element: any) => parseInt(element.Jaar)),
              ),
            ];

            const maxYear: any =
              yearsFiltered &&
              yearsFiltered.length !== 0 &&
              yearsFiltered.reduce((prev, current) =>
                prev > current ? prev : current,
              );
            const minYear: any =
              yearsFiltered &&
              yearsFiltered.length !== 0 &&
              yearsFiltered.reduce((prev, current) =>
                prev < current ? prev : current,
              );

            for (let i = maxYear + 1; i <= maxYearDim; i++) {
              lineData.push({ Dimensie: dim, Jaar: i, Verhouding: null });
            }

            for (let i = minYearDim; i < minYear; i++) {
              lineData.push({ Dimensie: dim, Jaar: i, Verhouding: null });
            }
          } else {
            lineData.push({
              Dimensie: dim,
              Jaar: maxYearDim,
              Verhouding: null,
            });
          }

          lineData.sort(function (a, b) {
            const keyA = parseInt(a.Jaar);
            const keyB = parseInt(b.Jaar);
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          lineData.color = color;
          lineData.max = parseInt(maxLineValue);
          lineData.unit = dim === 'Deelname NT2-cursus' ? '' : '%';

          let delta = null;
          if (data && data.length !== 0) {
            const previousYear = data.filter((el) => {
              return (
                parseInt(el.Jaar) === parseInt(maxYearDimCat) - 1 &&
                el.Dimensie === dim &&
                el['Soort_waarde'] === d
              );
            });
            const currentYear = data.filter((el) => {
              return (
                parseInt(el.Jaar) === parseInt(maxYearDimCat) &&
                el.Dimensie === dim &&
                el['Soort_waarde'] === d
              );
            });

            if (
              currentYear &&
              currentYear.length > 0 &&
              previousYear &&
              previousYear.length > 0
            ) {
              delta = currentYear[0].Verhouding - previousYear[0].Verhouding;
            }
          }

          const markData = delta;

          tmp[dim] = { bar: barData, line: lineData, mark: markData };
        });
        return tmp;
      });

      setGridData(newGridData);
    }
  };

  useEffect(() => {
    updateRadioButtonsState();
    prepGridData();
  }, [
    highlightedCategories,
    selectedYear,
    selectedCategory,
    selectedRegio,
    highlightedDimension,
    categories,
    dimensions,
    selectedIndicator,
    data,
  ]);

  function arrayRemoveValue(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  const handleChangeDimension = (e) => {
    setHighlightedDimension(e.target.value);
  };

  const handleMouseEnterCategory = (e) => {
    const title = e.currentTarget.dataset.title;
    if (title === highlightedDimension || title === 'Categorisatie') {
      const tmp = e.currentTarget.id.substring(3);
      setHoveredCategory(tmp);
    }
  };

  const handleClickCategory = (e) => {
    let tmp = e.currentTarget.id.substring(3);
    let tmpArray = [...highlightedCategories];
    if (highlightedCategories.includes(tmp)) {
      tmpArray = arrayRemoveValue(tmpArray, tmp);
    } else {
      tmpArray.push(tmp);
    }
    setHighlightedCategories(tmpArray);
  };

  const handleMouseLeaveCategory = (e) => {
    setHoveredCategory('');
  };

  // const fill = (element, dim) => {
  //   return hoveredCategory === element[categoryField] &&
  //     dim === highlightedDimension
  //     ? hoverColor
  //     : dim === highlightedDimension
  //     ? element[dim]
  //       ? element[dim].bar.color
  //       : colors[0]
  //     : 'gray';
  // };

  // const fillCategory = (element) => {
  //   return hoveredCategory === element[categoryField]
  //     ? hoverColor
  //     : element[dimensions[0]]
  //     ? element[dimensions[0]].bar.color
  //     : colors[0];
  // };

  const fill = (element, dim) => {
    return dim === highlightedDimension
      ? element[dim]
        ? element[dim].bar.color
        : colors[0]
      : colors[0];
  };

  const fillCategory = (element) => {
    return element[dimensions[0]]
      ? element[dimensions[0]].bar.color
      : colors[0];
  };

  const opacityBar = (element, dim) => {
    let opac = 1;
    if (highlightedCategories.includes(element[categoryField])) {
      if (hoveredCategory === '') {
        opac = 1;
      } else {
        if (
          hoveredCategory === element[categoryField] &&
          dim === highlightedDimension
        ) {
          opac = 1;
        } else {
          if (dim !== highlightedDimension) {
            opac = 1;
          } else {
            if (highlightedCategories.includes(hoveredCategory)) {
              opac = 0.4;
            } else {
              opac = 1;
            }
          }
        }
      }
    } else {
      opac = 0.05;
    }
    return opac;
  };

  const opacityCategory = (element) => {
    let opac = 1;
    if (highlightedCategories.includes(element[categoryField])) {
      if (hoveredCategory === '') {
        opac = 1;
      } else {
        if (hoveredCategory === element[categoryField]) {
          opac = 1;
        } else {
          if (highlightedCategories.includes(hoveredCategory)) {
            opac = 0.4;
          } else {
            opac = 1;
          }
        }
      }
    } else {
      opac = 0.2;
    }
    return opac;
  };

  const opacityText = (element) => {
    let opac = 1;
    if (highlightedCategories.includes(element[categoryField])) {
      opac = 1;
    } else {
      opac = 0.3;
    }
    return opac;
  };

  const mouseOverHandler = (e) => {
    let i = [...categories[selectedCategory]].indexOf(hoveredCategory);
    setPayloadIndex(i);
  };

  const graphLayout = (element, dim) => {
    if (dimensions.length > 3) {
      return (
        <div>
          <div
            className={`vl-col--9-12 vl-u-float-left ${paddingLeft2} `}
            style={{ height: heightRow }}
            id={`tr_${element[categoryField]}`}
          >
            <SimpleBarChart
              element={element}
              dim={dim}
              fill={fill(element, dim)}
              opacity={opacityBar(element, dim)}
              dataKey="Verhouding"
              barSize={barHeight}
            />
          </div>
          <div
            className={`vl-col--3-12 vl-u-float-left ${paddingLeft2} `}
            style={{ height: heightRow }}
            id={`tr_${element[categoryField]}`}
          >
            <div
              id={`tr_${element[categoryField]}`}
              style={{
                height: heightRow,
                textAlign: 'end',

                paddingRight: 6,
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <p>
                {element[dim]
                  ? element[dim].mark === null
                    ? '/'
                    : dim === 'Deelname NT2-cursus'
                    ? element[dim].mark > 0
                      ? `+${parseInt(element[dim].mark)}`
                      : parseInt(element[dim].mark)
                    : element[dim].mark > 0
                    ? `+${percentFormatter(element[dim].mark)}`
                    : percentFormatter(element[dim].mark)
                  : null}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className={`vl-col--7-12 vl-u-float-left ${paddingLeft2} `}
            style={{ height: heightRow }}
            id={`tr_${element[categoryField]}`}
          >
            <SimpleBarChart
              element={element}
              dim={dim}
              fill={fill(element, dim)}
              opacity={opacityBar(element, dim)}
              dataKey="Verhouding"
              barSize={barHeight}
            />
          </div>
          <div
            className={`vl-col--3-12 vl-u-float-left ${paddingLeft2}`}
            style={{ height: heightRow }}
            id={`tr_${element[categoryField]}`}
            onMouseOver={mouseOverHandler}
          >
            <SimpleLineChart
              element={element}
              dim={dim}
              dataKey="Verhouding"
              stroke={fill(element, dim)}
              opacity={opacityBar(element, dim)}
            />
          </div>
          <div
            className={`vl-col--2-12 vl-u-float-left ${paddingLeft2} `}
            style={{ height: heightRow }}
            id={`tr_${element[categoryField]}`}
          >
            <div
              id={`tr_${element[categoryField]}`}
              style={{
                height: heightRow,
                textAlign: 'end',
                paddingTop: 15,
                paddingRight: 6,
              }}
            >
              <p className={''}>
                {element[dim]
                  ? element[dim].mark === null
                    ? '/'
                    : dim === 'Deelname NT2-cursus'
                    ? element[dim].mark > 0
                      ? `+${parseInt(element[dim].mark)}`
                      : parseInt(element[dim].mark)
                    : element[dim].mark > 0
                    ? `+${percentFormatter(element[dim].mark)}`
                    : percentFormatter(element[dim].mark)
                  : null}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  const updateRadioButtonsState = () => {
    const tmp = {};
    dimensions.forEach((dimension) => {
      tmp[dimension] = highlightedDimension === dimension ? true : false;
    });
    setRadioButtonsState(tmp);
  };

  if (dimensions && dimensions.length !== 0) {
    return (
      <div>
        <header className="vl-info-tile__header" role="presentation">
          <div className="vl-info-tile__header__wrapper">
            <table className="vl-data-table vl-data-table--grid">
              <caption
                className="vl-info-tile__header__title"
                style={{
                  width: '100%',
                  textAlign: 'left',
                  minHeight: isBigScreen ? 32 : 20,
                }}
              >
                {selectedIndicatorLong && selectedIndicatorLong} {selectedYear}
              </caption>
              <thead>
                <tr>
                  {headerData.map((element) => {
                    if (element !== 'id') {
                      if (element === 'Soort_waarde') {
                        return <th key={element} style={{ width: 180 }}></th>;
                      } else {
                        if (dimensions.includes(element)) {
                          return (
                            <th
                              key={element}
                              style={{
                                opacity:
                                  element === highlightedDimension ? 1 : 0.6,
                              }}
                            >
                              <label
                                className="vl-radio"
                                style={{ marginRight: 0 }}
                                htmlFor={`group-dimension-radio-${element}`}
                              >
                                <input
                                  className="vl-radio__toggle"
                                  type="radio"
                                  id={`group-dimension-radio-${element}`}
                                  name="group-dimension"
                                  value={element}
                                  onChange={handleChangeDimension}
                                  checked={radioButtonsState[element]}
                                />
                                <div className="vl-radio__label">{element}</div>
                              </label>
                            </th>
                          );
                        }
                      }
                    } else return null;
                  })}
                </tr>
              </thead>
              <tbody>
                {gridData.map((element, i) => {
                  if (element[dimensions[0]] !== undefined) {
                    return (
                      <tr
                        key={element.id}
                        id={`tr_${element[categoryField]}`}
                        // onMouseEnter={(e) => {
                        //   handleMouseEnterCategory(e);
                        // }}
                        onClick={handleClickCategory}
                        style={{ cursor: 'pointer' }}
                      >
                        <td
                          data-title="Categorisatie"
                          style={{
                            minWidth: 180,
                            width: 180,

                            padding: `${paddingLabel}px 1.2rem`,
                            verticalAlign: 'middle',
                          }}
                          id={`tr_${element[categoryField]}`}
                          onMouseEnter={(e) => {
                            handleMouseEnterCategory(e);
                          }}
                          onMouseLeave={handleMouseLeaveCategory}
                        >
                          <p
                            style={{
                              textDecorationLine:
                                highlightedCategories.includes(
                                  element[categoryField],
                                )
                                  ? 'underline'
                                  : 'none',
                              textDecorationColor: fillCategory(element),
                              opacity: opacityText(element),
                            }}
                          >
                            <span
                              className={`${dot}`}
                              style={{
                                backgroundColor: fillCategory(element),
                                opacity: opacityCategory(element),
                              }}
                            ></span>
                            {element[categoryField]}
                          </p>
                        </td>
                        {dimensions.map((dim, cnt) => {
                          return (
                            <td
                              id={`tr_${element[categoryField]}`}
                              data-title={dim}
                              key={dim}
                              // className={`vl-col--1-4 ${padding2}`}
                              className={`${padding2}`}
                              style={{
                                opacity: dim === highlightedDimension ? 1 : 0.6,
                              }}
                              onMouseEnter={(e) => {
                                handleMouseEnterCategory(e);
                              }}
                              onMouseLeave={handleMouseLeaveCategory}
                            >
                              {graphLayout(element, dim)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  } else {
                    <div></div>;
                  }
                })}
              </tbody>
            </table>
          </div>
        </header>
      </div>
    );
  } else {
    return <div></div>;
  }
};

import { useStaticQuery, graphql, navigate } from 'gatsby';
import React from 'react';
import slugify from 'slugify';
import { useData } from '../../contexts';
import { MiniTable } from '../MiniTable/MiniTable';

interface Props {
  currentIndicator: any;
}

export const IndicatorForm = ({ currentIndicator }: Props) => {
  const {
    allDimensions,
    dimensions,
    setDimensions,
    highlightedDimension,
    setHighlightedDimension,
    categoryField,
    setCategoryField,
    setCategories,
    dropdownCategories,
    setSelectedCategory,
    dropdownCategoriesDefault,
    setHighlightedCategories,
    isDesktop,
    isBigScreen,
  } = useData();

  const queryData = useStaticQuery(graphql`
    query findIndicatorsDrawer {
      allNodeIndicator {
        nodes {
          id
          title
          field_doelstelling
          field_volgorde
        }
      }
    }
  `);

  const indicators = queryData.allNodeIndicator.nodes;

  const handleChangeDimensions = (e: any) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const checked = target.checked;

    const tmpCategoryField = name.replace('checkbox_dimension_', ''); // Herkomst / Taal ...
    if (categoryField && categoryField !== tmpCategoryField) {
      setCategoryField(tmpCategoryField);
      setCategories(dropdownCategories[tmpCategoryField]);
      setSelectedCategory(dropdownCategoriesDefault[tmpCategoryField]);

      setDimensions(allDimensions[tmpCategoryField]);
      setHighlightedDimension(allDimensions[tmpCategoryField][0]);

      let ddKeys = Object.keys(dropdownCategories[tmpCategoryField]);

      setHighlightedCategories([
        ...dropdownCategories[tmpCategoryField][
          dropdownCategoriesDefault[tmpCategoryField]
        ],
      ]);
    } else {
      if (checked && !dimensions.includes(value)) {
        let tmp = [...dimensions, value];

        let intersection = allDimensions[tmpCategoryField].filter((x) =>
          tmp.includes(x),
        );

        setDimensions(intersection);
        if (tmp.length === 1) {
          setHighlightedDimension(value);
        }
      } else {
        let tmp = dimensions.filter((dimension) => dimension !== value);
        if (dimensions.length > 1) {
          setDimensions(tmp);
        }
        if (highlightedDimension === value) {
          if (tmp.length !== 0) {
            setHighlightedDimension(tmp[0]);
          }
        }
      }
    }
  };

  const handleChangeIndicator = (e: any) => {
    navigate(`/themas/${slugify(e.target.value)}`);
  };

  return (
    <form
      className="vl-form-grid vl-form-grid--v-bottom "
      onSubmit={(e) => e.preventDefault()}
      style={{
        position: 'sticky',
        top: 0,
      }}
    >
      <div className="vl-form-col--1-1 vl-u-spacer-bottom vl-u-spacer-top">
        <label
          htmlFor="select_indicator"
          className="vl-form__label __field__label"
        >
          Kies een thema
        </label>
        <select
          name="indicator"
          id="select_indicator"
          onChange={handleChangeIndicator}
          value={currentIndicator.title}
          className="vl-select--block vl-select vl-select--block"
          tabIndex={0}
        >
          {indicators && indicators.length > 0
            ? indicators
                .sort((a, b) => a.field_volgorde - b.field_volgorde)
                .map((element, index) => (
                  <option
                    key={`option_indicator_${index}`}
                    value={element.title}
                  >
                    {element.title}
                  </option>
                ))
            : 'Loading...'}
        </select>
      </div>
      <label className="vl-form__label __field__label">Kies dimensies</label>
      {Object.keys(allDimensions).map((key, i) => {
        return (
          <div key={key}>
            {Object.keys(allDimensions).length > 1 && (
              <label
                className="vl-form__label vl-form__label--light vl-col--1-1"
                style={{ marginLeft: '0.5rem' }}
              >
                {key.replaceAll('_', ' ')}
              </label>
            )}
            {allDimensions[key].map((dimension, index) => (
              <label
                key={`dimensions-${index}_${key}`}
                className="vl-checkbox vl-col--1-1 "
                htmlFor={`checkbox_dimension_${index}_${key}`}
                style={{ paddingRight: '1rem', marginLeft: '0.7rem' }}
              >
                <input
                  className="vl-checkbox__toggle "
                  type="checkbox"
                  id={`checkbox_dimension_${index}_${key}`}
                  name={`checkbox_dimension_${key}`}
                  onChange={handleChangeDimensions}
                  value={dimension}
                  checked={dimensions.includes(dimension)}
                />
                <div className="vl-checkbox__label">
                  <i className="vl-checkbox__box" aria-hidden="true"></i>
                  {dimension}
                </div>
              </label>
            ))}
            <div className="vl-u-spacer-bottom"></div>
          </div>
        );
      })}
      {(isDesktop || isBigScreen) && (
        <div style={{ marginTop: 20, borderTop: '1px solid #ccc' }}>
          <MiniTable currentIndicator={currentIndicator} />
        </div>
      )}
    </form>
  );
};

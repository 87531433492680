import { Indicator } from '../models';

export const mapToIndicatorModel = (indicator: {
  id: string;
  title: string;
  field_titel_kort: string;
  field_doelstelling: string;
  field_volgorde: number;
  relationships?: any;
}): Indicator => {
  return {
    id: indicator.id,
    title: indicator.title,
    titel_kort: indicator.field_titel_kort,
    doelstelling: indicator.field_doelstelling,
    volgorde: indicator.field_volgorde,
    sources: indicator.relationships?.field_bron,
    file: indicator.relationships?.field_xls,
  };
};
